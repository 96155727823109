import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import { Heading, Box, Flex, Button } from "@chakra-ui/core";
import Container from "../components/Container";
import Banner from "../components/Banner";
import FormattedContent from "../components/FormattedContent";
import { Input, Textarea, Text } from "@chakra-ui/core";
import { useToast } from "@chakra-ui/core";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const ContactPageTemplate = ({
  title,
  content,
  heading,
  subheading,
  image,
  callToAction,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;
  const toast = useToast();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [botField, setBotField] = React.useState("");

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        name,
        email,
        message,
        phone
      })
    })
      .then(() => {
        setName("");
        setEmail("");
        setMessage("");
        setPhone("");

        toast({
          title: "Bericht verzonden.",
          description: "We hebben je bericht goed ontvangen.",
          status: "success",
          duration: 9000,
          isClosable: true
        });
      })
      .catch(error => {
        toast({
          title: "Er is iets misgegaan.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Box h={["300px", "280px", "250px", "200px"]}>
          <Container position="relative">
            <Banner.Container>
              <Banner.Image image={image} />
            </Banner.Container>
          </Container>
        </Box>
        <Box mb={[50, 50, 50, 75]}>
          <Heading as="h1" size="2xl" color="#4a93f7">
            {heading}
          </Heading>
          <Heading as="h2" size="lg" color="#5c626e" fontWeight="normal">
            {subheading}
          </Heading>
        </Box>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box flexGrow={1} mb={[50, 50, 0]}>
            <Box mb={20}>
              <FormattedContent>
                <PageContent content={content} />
              </FormattedContent>
            </Box>

            <form
              name="contact-form"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact-form" />
              <div hidden>
                <label htmlFor={"bot-field"}>
                  Don’t fill this out:{" "}
                  <input
                    name="bot-field"
                    onChange={e => setBotField(e.target.value)}
                    value={botField}
                  />
                </label>
              </div>
              <Box mb={4}>
                <label htmlFor={"name"}>
                  <Text color="gray.500" fontWeight="semibold" fontSize="lg">
                    Naam
                  </Text>
                  <Input
                    type="text"
                    name="name"
                    minLength="2"
                    onChange={e => setName(e.target.value)}
                    value={name}
                    isRequired
                  />
                </label>
              </Box>
              <Box mb={4}>
                <label htmlFor={"email"}>
                  <Text color="gray.500" fontWeight="semibold" fontSize="lg">
                    E-mailadres
                  </Text>
                  <Input
                    type="email"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    isRequired
                    value={email}
                  />
                </label>
              </Box>
              <Box mb={4}>
                <label htmlFor={"phone"}>
                  <Text color="gray.500" fontWeight="semibold" fontSize="lg">
                    Telefoonnummer
                  </Text>
                  <Input
                    type="tel"
                    name="phone"
                    onChange={e => setPhone(e.target.value)}
                    isRequired
                    value={phone}
                  />
                </label>
              </Box>
              <Box mb={4}>
                <label htmlFor={"message"}>
                  <Text color="gray.500" fontWeight="semibold" fontSize="lg">
                    Bericht
                  </Text>
                  <Textarea
                    name="message"
                    minLength="2"
                    height={100}
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                    isRequired
                  />
                </label>
              </Box>
              <Box mb={4}>
                <Button type="submit">Verzenden</Button>
              </Box>
            </form>
          </Box>
          {callToAction && (
            <Box w={["100%", "100%", "420px"]} pl={[0, 0, 80]} flexShrink={0}>
              <Button
                as="a"
                rightIcon="arrow-forward"
                size="lg"
                w="100%"
                href={callToAction.url}
              >
                {callToAction.text}
              </Button>
            </Box>
          )}
        </Flex>
      </Container>
    </>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.object,
  callToAction: PropTypes.object
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        heading={post.frontmatter.heading}
        subheading={post.frontmatter.subheading}
        image={post.frontmatter.image}
        callToAction={post.frontmatter.callToAction}
        content={post.html}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        callToAction {
          url
          text
        }
      }
    }
  }
`;
